#main {
  perspective: 50vw;
}
#card {
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
}
#card,
#card * {
  transform-style: preserve-3d;
}
#logo {
  filter: drop-shadow(4px 12px 6px rgba(0, 0, 0, 0.4));
  transform: translateZ(30px);
}
